import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Container, Header, Segment } from "semantic-ui-react"

import Layout from "../components/Layout"
import ProjectRoll from "../components/ProjectRoll"

export const PortfolioPageTemplate = ({ heading, description, body }) => (
  <>
    <Container>
      <Segment inverted>
        <Header>Portfolio</Header>
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </Segment>
      <br />
      <ProjectRoll />
    </Container>
  </>
)

PortfolioPageTemplate.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  body: PropTypes.string,
}

const PortfolioPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { html } = data.markdownRemark

  return (
    <Layout>
      <PortfolioPageTemplate
        heading={frontmatter.heading}
        description={frontmatter.description}
        body={html}
      />
    </Layout>
  )
}

PortfolioPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PortfolioPage

export const portfolioPageQuery = graphql`
  query PortfolioPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        description
      }
      html
    }
  }
`
